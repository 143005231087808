import { defineStore } from "pinia";
import { createAvatar } from "~/shared/utils";
import {
  Client,
  ClientLayout,
  ClientResponse,
  ClientTargetMetric,
} from "~/types/Client";
import { CustomMetricRule, MetricsFilter } from "~/types/Metrics";
import { Provider } from "~/types/shared";
import { ClientOnboardingTaskId } from "~/types/Onboarding";

export const CLIENT_STORE = "client-store";
export const LIST_CLIENTS_PRIORITY = 0;
export const APP_USER_CLIENT_PRIORITY = 1;
export const SET_PAGE_CLIENT_PRIORITY = 2;
// Ensure dropdown has same priority as set active client
// Otherwise, the client cannot be changed via dropdown after duplicating report
export const SELECT_DROPDOWN_CLIENT_PRIORITY = 3;
export const SET_ACTIVE_CLIENT_PRIORITY = 3;

export const useClientStore = defineStore({
  id: CLIENT_STORE,
  state: () => {
    return {
      clients: [],
      activeClientId: null,
      lastSetClientPriority: -1,
    } as {
      clients: Array<Client>;
      activeClientId: number | null;
      lastSetClientPriority: number;
    };
  },
  actions: {
    reset(log?: (message: string) => void) {
      if (log) {
        log("Resetting client store");
      }
      this.clients = [];
      this.activeClientId = null;
      this.lastSetClientPriority = -1;
    },

    async listClients(log?: (message: string) => void) {
      let requestId = null;
      if (log) {
        requestId = Math.random(); // Unique ID for this request
        const clientNames = this.clients
          .map((client) => client.displayName)
          .join(", ");
        log(`[${requestId}] listClients called, current state: ${clientNames}`);
      }
      const { data, error } = await useDatAdsApiFetch<{
        data: { clients: Array<ClientResponse> };
      }>("client");
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        if (log && requestId) {
          const clientNames = data.value.data.clients
            .map((client) => client.name)
            .join(", ");
          log(`[${requestId}] received clients: ${clientNames}`);
        }
        const { getMappedClients } = useClients();
        this.clients = getMappedClients(data.value.data.clients);
        if (this.clients.length > 0) {
          const appUserStore = useAppUserStore();
          if (
            appUserStore.appUserMe &&
            appUserStore.appUserMe.activeClientId != null &&
            this.clients.some(
              (client) => client.id === appUserStore.appUserMe?.activeClientId,
            )
          ) {
            const clientStore = useClientStore();
            clientStore.setActiveClientId(
              appUserStore.appUserMe.activeClientId,
              APP_USER_CLIENT_PRIORITY,
            );
          }
          this.setActiveClientId(this.clients[0].id, LIST_CLIENTS_PRIORITY);
        }
      }
      return this.clients;
    },

    async listClientsOfUser(userId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { clients: Array<ClientResponse> };
      }>(`client/user/${userId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedClients } = useClients();
        return getMappedClients(data.value.data.clients);
      }
      return [];
    },

    async deleteClient(clientId: number) {
      const { error } = await useDatAdsApiFetch(`client/${clientId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.clients = this.clients.filter((client) => client.id !== clientId);
      if (clientId === this.activeClientId && this.clients.length > 0) {
        this.setActiveClientId(this.clients[0].id, LIST_CLIENTS_PRIORITY, true);
      }
      await useAdAccountStore().listAdAccounts();
      await this.listClients();
      return null;
    },

    async createClients(
      input: {
        name: string;
        website: string | null;
        logo?: File | null;
      }[],
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { clients: Array<ClientResponse> };
      }>("client/create", {
        method: "POST",
        body: input.map((client) => ({
          name: client.name,
          website: client.website !== "" ? client.website : null,
        })),
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value == null) {
        return null;
      }
      const createdClients = [];
      for (const client of data.value.data.clients) {
        const clientId = client.id;
        let logoUrl: string | null = null;
        const clientInfoMaybe = input
          .map((c) => ({ ...c, website: c.website !== "" ? c.website : null }))
          .find((c) => c.name === client.name && c.website === client.website);
        if (clientInfoMaybe != null && clientInfoMaybe.logo != null) {
          const { url } = await this.uploadLogo({
            logo: clientInfoMaybe.logo,
            clientId,
          });
          logoUrl = url;
        }
        const { getMappedClients } = useClients();
        const clients = getMappedClients([
          {
            ...client,
            logo:
              typeof logoUrl === "string"
                ? logoUrl
                : typeof client.logo === "string"
                  ? client.logo
                  : createAvatar(client.name, "initials"),
          },
        ]);
        createdClients.push(...clients);
        this.clients = [...this.clients, ...clients];
      }
      await useAdAccountStore().listAdAccounts();
      await this.listClients();
      // Return originally created clients as list clients will return a different order maybe
      return createdClients;
    },

    async updateClient(input: {
      clientId: number;
      name?: string | undefined | null;
      website?: string | undefined | null;
      logo?: File | undefined | null;
      colorCodingLayout?: ClientLayout | undefined | null;
      targetMetrics?: Array<ClientTargetMetric> | undefined | null;
      primaryMetrics?: string[] | undefined | null;
      secondaryMetrics?: string[] | undefined | null;
      sorts?: string[] | undefined | null;
      attributionSettings?: string[] | undefined | null;
      globalFilters?: Record<Provider, MetricsFilter[][]> | undefined | null;
      defaultProvider?: Provider | undefined | null;
      skipGettingStarted?: boolean | undefined | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { clients: Array<ClientResponse> };
      }>("client", {
        method: "PATCH",
        body: [
          {
            clientId: input.clientId,
            name: input.name,
            website: input.website !== "" ? input.website : null,
            colorCodingLayout: input.colorCodingLayout,
            targetMetrics: input.targetMetrics,
            primaryMetrics: input.primaryMetrics,
            secondaryMetrics: input.secondaryMetrics,
            sorts: input.sorts,
            attributionSettings: input.attributionSettings,
            globalFilters: input.globalFilters,
            defaultProvider: input.defaultProvider,
            skipGettingStarted: input.skipGettingStarted,
          },
        ],
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        let logoUrl: string | null = null;
        if (input.logo) {
          const { errorMessage, url } = await this.uploadLogo({
            logo: input.logo,
            clientId: input.clientId,
          });
          if (errorMessage) {
            return errorMessage;
          }
          logoUrl = url;
        }
        const { getMappedClients } = useClients();
        const client = getMappedClients([
          {
            ...data.value.data.clients[0],
            logo:
              typeof logoUrl === "string"
                ? logoUrl
                : typeof data.value.data.clients[0].logo === "string"
                  ? data.value.data.clients[0].logo
                  : createAvatar(data.value.data.clients[0].name, "initials"),
          },
        ])[0];
        this.clients = this.clients.map((c) => {
          if (c.id === input.clientId) {
            return client;
          }
          return c;
        });
      }
      return null;
    },

    async uploadLogo(input: { logo: File; clientId: number }) {
      const formData = new FormData();
      formData.append("file", input.logo, input.logo.name);

      const { data, error } = await useDatAdsApiFetch<{
        data: { url: string };
      }>(`client/${input.clientId}/logo`, {
        method: "POST",
        body: formData,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return { errorMessage, url: null };
      }
      if (data.value) {
        return {
          url: data.value.data.url,
          errorMessage: null,
        };
      }
      return { errorMessage: null, url: null };
    },

    async listSimilarClients(
      dto: {
        id: string; // Uniquely identifies the request
        allowedHammingDistance: number;
        query: string;
      }[],
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          clients: Array<{
            id: string;
            similarClients: Array<ClientResponse & { hammingDistance: number }>;
          }>;
        };
      }>("client/similar", {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        return data.value.data.clients;
      }
      return [];
    },

    async setupClient(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { success: boolean };
      }>(`client/${clientId}/setup`, {
        method: "POST",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        return data.value.data.success;
      }
      return null;
    },

    async completeTasks(clientId: number, taskIds: ClientOnboardingTaskId[]) {
      const { error } = await useDatAdsApiFetch(
        `client/${clientId}/tasks/complete`,
        {
          method: "POST",
          body: taskIds.map((taskId) => ({ taskId })),
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.clients = this.clients.map((client) => {
        if (client.id === clientId) {
          return {
            ...client,
            completedTasks: Array.from(
              new Set([...client.completedTasks, ...taskIds]),
            ),
          };
        }
        return client;
      });
      return null;
    },

    async uncompleteTasks(clientId: number, taskIds: ClientOnboardingTaskId[]) {
      const { error } = await useDatAdsApiFetch(
        `client/${clientId}/tasks/uncomplete`,
        {
          method: "POST",
          body: taskIds.map((taskId) => ({ taskId })),
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.clients = this.clients.map((client) => {
        if (client.id === clientId) {
          return {
            ...client,
            completedTasks: client.completedTasks.filter(
              (taskId) => !taskIds.includes(taskId),
            ),
          };
        }
        return client;
      });
      return null;
    },

    setActiveClientId(clientId: number, priority: number, force = false) {
      // Only set the active client if the priority is higher than the last set priority
      // This is to prevent the active client from being changed when the client is changed from multiple places simultaneously
      // e.g. when the clients are loaded initially and when the client is set from a report page
      if (force || priority >= this.lastSetClientPriority) {
        this.activeClientId = clientId;
        this.lastSetClientPriority = priority;
      }
    },

    addCustomMetricRule(rule: CustomMetricRule, clientId: number) {
      this.clients = this.clients.map((client) => {
        if (client.id === clientId) {
          client.customMetricRules = [...client.customMetricRules, rule];
        }
        return client;
      });
    },

    updateCustomMetricRule(dto: {
      newRule: CustomMetricRule;
      oldRule: CustomMetricRule;
    }) {
      this.clients = this.clients.map((client) => {
        client.customMetricRules = client.customMetricRules.map((rule) => {
          if (rule.customMetricRuleId === dto.oldRule.customMetricRuleId) {
            return dto.newRule;
          }
          return rule;
        });
        return client;
      });
    },

    deleteCustomMetricRule(customMetricRuleId: number) {
      this.clients = this.clients.map((client) => {
        client.customMetricRules = client.customMetricRules.filter(
          (rule) => rule.customMetricRuleId !== customMetricRuleId,
        );
        return client;
      });
    },
  },
  getters: {
    activeClient(state): Client | null {
      const c = state.clients.find(
        (client) => client.id === this.activeClientId,
      );
      return c ?? null;
    },

    getClientById:
      (state) =>
      (clientId: number | null): Client | null => {
        const c = state.clients.find((client) => client.id === clientId);
        return c ?? null;
      },

    getClientByNameAndWebsite:
      (state) =>
      (name: string, website: string | null): Client | null => {
        const c = state.clients.find(
          (client) => client.displayName === name && client.website === website,
        );
        return c ?? null;
      },

    activeClientCurrency(_state): string {
      if (this.activeClient == null) {
        return "EUR";
      }
      return this.currency(this.activeClient.id);
    },

    /**
     * Return the currency of the client with the given id.
     * If multiple ad accounts are associated with the client, the most used currency is returned.
     */
    currency: (state) => (clientId: number) => {
      const client = state.clients.find((c) => c.id === clientId);
      if (client == null) {
        return "EUR";
      }
      const adAccountStore = useAdAccountStore();
      const adAccountsOfClient = adAccountStore.adAccounts.filter((adAccount) =>
        adAccount.clientIds.includes(clientId),
      );
      if (adAccountsOfClient.length === 0) {
        return "EUR";
      }
      const mostCommonCurrency = adAccountsOfClient
        .map((adAccount) => adAccount.currency)
        .reduce(
          (acc, curr) => {
            if (acc[curr] == null) {
              acc[curr] = 0;
            }
            acc[curr] += 1;
            return acc;
          },
          {} as Record<string, number>,
        );
      return Object.entries(mostCommonCurrency).reduce(
        (acc, curr) => {
          if (curr[1] > acc[1]) {
            return curr;
          }
          return acc;
        },
        ["EUR", 0],
      )[0];
    },

    activeClientProvider(_state): Provider {
      return this.activeClient != null
        ? this.provider(this.activeClient.id)
        : Provider.META;
    },

    /**
     * Return the provider of the client with the given id.
     * If multiple ad accounts are associated with the client, the most used provider is returned.
     */
    provider: (state) => (clientId: number) => {
      const client = state.clients.find((c) => c.id === clientId);
      if (client == null) {
        return Provider.META;
      }
      if (client.defaultProvider != null) {
        return client.defaultProvider;
      }
      const adAccountStore = useAdAccountStore();
      const adAccountsOfClient = adAccountStore.adAccounts.filter((adAccount) =>
        adAccount.clientIds.includes(clientId),
      );
      if (adAccountsOfClient.length === 0) {
        return Provider.META;
      }
      const mostCommonProvider = adAccountsOfClient
        .map((adAccount) => adAccount.provider)
        .reduce(
          (acc, curr) => {
            if (acc[curr] == null) {
              acc[curr] = 0;
            }
            acc[curr] += 1;
            return acc;
          },
          {} as Record<Provider, number>,
        );
      return Object.entries(mostCommonProvider).reduce(
        (acc, curr) => {
          if (curr[1] > acc[1]) {
            return curr;
          } else if (curr[1] === acc[1] && curr[0] === Provider.META) {
            return curr;
          }
          return acc;
        },
        [Provider.META, 0],
      )[0] as Provider;
    },

    clientProviders:
      (state) =>
      (clientId: number): Array<Provider> => {
        const client = state.clients.find((c) => c.id === clientId);
        if (client == null) {
          return [];
        }
        const adAccountStore = useAdAccountStore();
        const adAccountsOfClient = adAccountStore.adAccounts.filter(
          (adAccount) => adAccount.clientIds.includes(clientId),
        );
        if (adAccountsOfClient.length === 0) {
          return [];
        }
        const providers = adAccountsOfClient.flatMap((aA) => aA.provider);
        const unique = Array.from(new Set(providers));
        if (unique.length === 0) {
          return [Provider.META];
        }
        return unique;
      },

    customMetricRules:
      (state) =>
      (dto: {
        clientId: number;
        providers: Provider[];
      }): CustomMetricRule[] => {
        const client = state.clients.find((c) => c.id === dto.clientId);
        if (client == null) {
          return [];
        }
        return client.customMetricRules.filter((rule) =>
          dto.providers.includes(rule.provider),
        );
      },

    isCustomMetric: (state) => (metricId: string) => {
      return state.clients.some((client) =>
        client.customMetricRules.some((rule) => rule.metricName === metricId),
      );
    },

    providerOfCustomMetric: (state) => (customMetricId: string) => {
      const rules = state.clients
        .flatMap((client) => client.customMetricRules)
        .filter((rule) => rule.metricName === customMetricId);
      if (rules.length === 0) {
        return null;
      }
      return rules[0].sourceProvider;
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClientStore, import.meta.hot));
}
